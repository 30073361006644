import React, { Suspense, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import "./assets/css/style.css";
import "./assets/css/iconfont.css";
import "./assets/css/skin/skin-1.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useSecurity from "./Components/Security/Security";
import { useSelector } from "react-redux";
import { lazy } from "react";
import LazyLoader from "./Components/Loader/LazyLoader";
import CampageList from "./Components/CampageList/CampageList";

const HomePage = lazy(() => import("./pages/HomePage"));

const Shop = lazy(() => import("./pages/Shop"));
const ProductDetails = lazy(() => import("./Components/Shop/Productdetails"));
const Blogs = lazy(() => import("./pages/Blogs"));
const Faq = lazy(() => import("./pages/Faq"));
const Footer = lazy(() => import("./Components/Footer/Footer"));
const About = lazy(() => import("./pages/About"));

const Contact = lazy(() => import("./pages/Contact"));
const Login = lazy(() => import("./pages/Login"));
const Signup = lazy(() => import("./pages/Signup"));
const PrivateRoute = lazy(() => import("./router/PrivateRoute"));
const Cart = lazy(() => import("./pages/Cart"));

const OtpPage = lazy(() => import("./pages/OtpPage"));
const ForgotPassWordPage = lazy(() => import("./pages/ForgotPassWordPage"));
const BlogDetailPage = lazy(() =>
  import("./Components/Blogpage/BlogDetailPage")
);
const WishlistDetailPage = lazy(() =>
  import("./Components/Header/WishlistDetailPage")
);
const CategoryPageShop = lazy(() =>
  import("./Components/Shop/CategoryPageLayout")
);

const Innernav = lazy(() => import("./Components/Header/Innernav"));
const Orders = lazy(() => import("./pages/Orders"));
const OrdersList = lazy(() => import("./pages/OrdersList"));
const OrderDetails = lazy(() => import("./pages/OrderDetails"));
const SubPages = lazy(() => import("./pages/SubPages"));

function App() {
  // useSecurity();
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const settingsData = useSelector((state) => state.settings.settingsData);
  const siteLogo = settingsData.find(
    (setting) => setting.key_name === "FAV_ICON"
  );
  const siteName = settingsData.find(
    (setting) => setting.key_name === "SITE_NAME"
  );
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = AppUrl + siteLogo?.key_value;
  }, [siteLogo?.key_value]);
  useEffect(() => {
    document.title = siteName?.key_value;
  }, [siteName?.key_value]);
  return (
    <div>
      <Innernav />
      <Suspense fallback={<LazyLoader />}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route
            path="/cart"
            element={
              <PrivateRoute>
                <Cart />
              </PrivateRoute>
            }
          />
          {/* <Route path="/shop-checkout" element={<CheckoutAddress />} /> */}
          <Route path="/product-details" element={<ProductDetails />} />
          <Route path="/otp" element={<OtpPage />} />
          <Route path="/forgot-password" element={<ForgotPassWordPage />} />
          <Route path="/blog-details" element={<BlogDetailPage />} />
          <Route path="/shop-wishlist" element={<WishlistDetailPage />} />
          <Route path="/shop/:categoryName" element={<CategoryPageShop />} />
          <Route path="/order-details" element={<Orders />}>
            <Route index element={<OrdersList />} />
            {/* <Route  path="orderlist" element={<OrdersList />} /> */}
            <Route path="wishlist" element={<WishlistDetailPage />} />
            <Route path="contact" element={<Contact />} />
            <Route path="view" element={<OrderDetails />} />
          </Route>
          <Route path="/sub-pages" element={<SubPages />} />
          <Route path="/campage-list" element={<CampageList />} />
        </Routes>
      </Suspense>

      <Footer />

      <ToastContainer
        toastStyle={{
          fontSize: "15px",
          fontWeight: "bold",
          color: "green",
          backgroundColor: "white",
          borderRadius: "5px",
          padding: "10px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
          width: "800px",
          maxWidth: "100%",
          margin: "0 auto",
          textAlign: "center",
          height: "60px",
          minHeight: "60px",
        }}
        style={{ top: "80px" }}
        toastClassName="custom-toast"
        progressClassName="custom-toast-progress"
        bodyClassName="custom-toast-progress-background"
      />
    </div>
  );
}

export default App;
